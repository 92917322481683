export function updateButtonStates(controls, contentState) {

    controls.forEach(function (control) { // Loop through all the [data-disclosure-control] elements in the document
        let ariaControls = control.getAttribute('aria-controls').split(' '); // Get the space-separated values of 'aria-controls' for all of them, and split them into separate strings in an 'ariaControls' array

        let targetElements = ariaControls.map(function (element) {  // Iterate through the 'ariaControls' array
            return document.getElementById(element); //For each item encountered in 'ariaControls', search the entire document for elements with an id matching the item's value, then return those elements and add them to a new array 'targetElements'.
        });

        let isOpened = targetElements.some(function (targetElement) { // Iterate through the 'targetElements' array, using 'some' which returns 'true' if at least 1 element in the array satisfies the condition specified inside it
            if (targetElement) {
                return targetElement.getAttribute('data-disclosure-content') === 'open'; // retrieves the value of each element's 'data-disclosure-content' attribute. Return only the elements that are either open, or opening. //todo: are we actually using the value 'opening' for anything?
            }
        });

        if (!contentState) {
            contentState = 'aria-expanded';
        }

        control.setAttribute(contentState, isOpened);

        if  (control.getAttribute('aria-selected')) {
            control.setAttribute('tabindex', isOpened ? '0' : '-1');
        }

        // Define selectors for disabled/enabled controls
        const closeSelector = `[data-disclosure-control=close][${contentState}=false]`;
        const openSelector = `[data-disclosure-control=open][${contentState}=true]`;

        // Controls that are specified as 'close-only' and do not have any corresponding expanded elements, and controls that are specified as 'open-only' and do not have any corresponding collapsed elements, should be disabled in the HTML
        const disabledControls = document.querySelectorAll(`${closeSelector}, ${openSelector}`);
        disabledControls.forEach(function (disabledControl) {
            disabledControl.setAttribute('disabled', 'disabled');
        });

        // Enable controls not meeting the disabled criteria
        const enabledControls = document.querySelectorAll(`[data-disclosure-control]:not(${closeSelector}):not(${openSelector})`);
        enabledControls.forEach(function (enabledControl) {
            enabledControl.removeAttribute('disabled');
        });

    });

}

//todo aria-pressed (toggle) button support (https://www.w3.org/WAI/ARIA/apg/patterns/button/examples/button/)