import { tns } from 'tiny-slider';

export function dienstenSlider() {
    let slider = document.getElementById('diensten-slider');
    if (slider) {
        slider = tns({
            container: '#diensten-slider',
            items: 1.125,
            gutter: 16,
            autoplay: false,
            nav: true,
            navPosition: 'bottom',
            mouseDrag: true,
            touch: true,
            controls: true,
            controlsPosition: 'bottom',
            controlsText: ['<span class="icon-basic icon-chevron-left-circled"></span>', '<span class="icon-basic icon-chevron-right-circled"></span>'],
            responsive: {
                576: {
                    items: 1.5
                },
                768: {
                    items: 2,
                },
                1024: {
                    items: 3
                }
            }
        });
    }

    return 'dienstenSlider';

}
