export function offcanvas() {

    'use strict';

    //Declare error messages and other global constants
    const missingAria = new TypeError("Offcanvas control is missing an aria-controls attribute");
    const emptyAria = new TypeError("The provided aria-controls attribute is empty");
    const scrollWidth = window.innerWidth - document.documentElement.clientWidth;
    const reducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)');

    function Offcanvas(element, options) {
        this.Init(element, options);
        this.openedControl = null; //Save reference to the control button that opened the offcanvas control, so focus can be set back to it when it closes
        this.zIndexCounter = 1050; // Initialize the z-index counter
        this.openedCount = 0; // Initialize counter for opened offcanvas elements
        this.targetElement = element;
        this.options = options; // Store the options object
    }

    Offcanvas.prototype = {

        /**
         * Constructor
         * @Param object options
         * @return void
         */
        Init: function (element, options) {
            this.options = Object.assign({
                contents: '.js-offcanvas-content',
                controls: '.js-navigation-toggler',
            }, options);

            this.contents = document.querySelectorAll(this.options.contents);
            this.controls = document.querySelectorAll(this.options.controls);

            this.Start();
        },

        /**
         * Start
         * @return void
         */
        Start: function () {
            const _this = this;

            const controls = this.controls;
            const contents = this.contents;

            //Find all offcanvas elements, and close them by default
            contents.forEach(function (content) {
                content.setAttribute('data-offcanvas-content', 'closed');
            });

            _this.UpdateButtonStates(controls);
            _this.SetEvents(controls, contents);

        },

        /**
         * UpdateButtonStates
         * @return void
         */
        UpdateButtonStates: function (controls) {
            setTimeout(function () {
                //Set the correct button states for each offcanvas control button in the document
                controls.forEach(function (control) {

                    //Get all space-separated values of the control button's aria-controls attribute
                    let ariaControls = control.getAttribute('aria-controls').split(' ');

                    let targetElements = ariaControls.map(function (element) {
                        return document.getElementById(element); //todo: Currently doesn't work if an element isn't present on the page. We should throw a warning to the author and still execute the rest of the code even if one of the elements is missing.
                    });

                    //Set aria-expanded to the correct value if one of the values of aria-controls matches an opened offcanvas element's id
                    let isOpened = targetElements.some(function (targetElement) {
                        return targetElement && targetElement.getAttribute('data-offcanvas-content') === 'open';
                    });
                    control.setAttribute('aria-expanded', isOpened);

                    //Control buttons that can only close offcanvas elements and control buttons that can only open offcanvas elements should be disabled when the corresponding offcanvas element is already closed or open, respectively
                    let closeControls = document.querySelectorAll('[data-offcanvas-control=close][aria-expanded=false], [data-offcanvas-control=open][aria-expanded=true]');
                    closeControls.forEach(function (closeControl) {
                        closeControl.setAttribute('disabled', 'disabled');
                    });

                    //Remove the disabled attribute for open or close control buttons when the declaration above does not match
                    let openControls = document.querySelectorAll('[data-offcanvas-control=open][aria-expanded=false], [data-offcanvas-control=close][aria-expanded=true]');
                    openControls.forEach(function (openControl) {
                        openControl.removeAttribute('disabled');
                    });

                    let toggleControl = control.querySelector('[data-offcanvas-control=toggle]');

                    if (toggleControl) {
                        let isAnyOpen = targetElements.some(function (targetElement) {
                            return targetElement && targetElement.getAttribute('data-offcanvas-content') === 'open';
                        });

                        toggleControl.setAttribute('aria-expanded', isAnyOpen ? 'true' : 'false');
                    }
                });
            });
        },

        /**
         * SetEvents
         * @return void
         */
        SetEvents: function (controls, contents) {
            const _this = this;

            //Listen for inputs and pass the type of control button as an argument
            controls.forEach(function (control) {
                const type = control.getAttribute('data-offcanvas-control');
                control.addEventListener('click', function () {
                    _this.fireEvent(this, type);
                });
            });

            //Listen for keyboard inputs when focus is inside the offcanvas element
            contents.forEach(function (content) {
                content.addEventListener('keydown', function(e) {
                    if (e.which === 27) {
                        //When esc is pressed, the offcanvas element should close
                        _this.closeOffcanvas(this);
                    } else if (e.which === 9  && content.getAttribute('aria-modal') === 'true') {
                        //When the offcanvas element is of type modal, focus should remain trapped inside the modal when the user presses tab / shift + tab
                        const focusableElements = this.querySelectorAll('a[href], button:not([disabled]), input:not([disabled]), select:not([disabled]), [contenteditable], textarea:not([disabled]), [tabindex]:not([tabindex="-1"])');
                        const firstFocusable = focusableElements[0];
                        const lastFocusable = focusableElements[focusableElements.length - 1];

                        if (e.shiftKey && document.activeElement === firstFocusable) {
                            //When focus is on the first focusable element and the user presses shift + tab, set focus to the last focusable element
                            e.preventDefault();
                            lastFocusable.focus();
                        } else if (!e.shiftKey && document.activeElement === lastFocusable) {
                            //When focus is on the last focusable element and the user presses tab, set focus to the first focusable element
                            e.preventDefault();
                            firstFocusable.focus();
                        }
                    }
                });
            });

        },

        /**
         * closeOffcanvas
         * @return void
         */
        closeOffcanvas: function (content, isModal = true) {
            const _this = this;

            const body = document.querySelector('body');
            const backdropDiv = content.nextElementSibling;

            if (isModal) {
                //Get the body element and backdrop
                backdropDiv.classList.remove('show');
                content.removeAttribute('aria-modal');
                // Decrement the zIndexCounter when closing
                _this.zIndexCounter--;
                _this.openedCount--;
            }

            //When the offcanvas element closes, trigger the closing animation, fade out the backdrop, and remove the aria-modal and role attributes
            content.classList.add('collapsing');
            content.removeAttribute('role');
            content.setAttribute('data-offcanvas-content', 'closed');

            //When the closing animation has finished, remove the backdrop from the DOM, and set the body to be scrollable again
            content.addEventListener('transitionend', function() {
                content.classList.remove('collapsing', 'expanded');
                if (isModal) {
                    backdropDiv.remove();
                    if (_this.openedCount <= 0) {
                        body.style.overflow = '';
                        body.style.paddingRight = '';
                    }
                    if (_this.openedCount < 0) {
                        _this.openedCount = 0;
                    }
                }

                // Set focus back to the control element that initially opened the offcanvas element, if it exists
                _this.openedControl.focus();

            }, {
                once: true
            });
            //Update the state of all the control buttons that need to be changed (todo: should probably trigger only once the transition has actually ended)
            this.UpdateButtonStates(this.controls);
        },

        openOffcanvas: function (content, control, isModal = true) {

            if (content.getAttribute('data-offcanvas-content') !== 'open') {

                const _this = this;

                //Get the body element and backdrop
                const body = document.querySelector('body');
                const backdropDiv = document.createElement('div');

                _this.openedControl = control; //Do we actually need this here?

                //Trigger the opening animation for the offcanvas element, and append the backdrop to it
                content.setAttribute('data-offcanvas-content', 'open');

                content.classList.add('expanding');

                if (isModal) {
                    //Attach an event listener to the backdrop that closes the offcanvas element on click
                    backdropDiv.classList.add('backdrop');
                    backdropDiv.addEventListener('click', function () {
                        _this.closeOffcanvas(content);
                    });
                    content.insertAdjacentElement('afterend', backdropDiv);

                    //Lock the scroll position for the document, and set a padding to it for devices that don't have floating scrollbars to avoid layout shifts
                    body.style.overflow = 'hidden';
                    body.style.paddingRight = scrollWidth + 'px';

                    // Increment the zIndexCounter and set z-index for the backdrop
                    _this.openedCount++;
                    _this.zIndexCounter++;
                    backdropDiv.style.zIndex = _this.zIndexCounter;
                    content.style.zIndex = _this.zIndexCounter + 1; // Increment for the offcanvas element

                    setTimeout(function () { //todo: This is kind of hacky, is there a better way to achieve this? If we don't set a delay here, we can't transition the backdrop after it has been created. This short delay still sometimes causes problems with the backdrop not fading in properly.
                        backdropDiv.classList.add('show');
                    }, 50);
                }

                //Once the opening animation for the offcanvas element has finished, we set aria-modal to true and role to dialog, and set focus on the offcanvas element. Because it has a tabindex of -1, it cannot be re-focussed once focus leaves the element.
                content.addEventListener('transitionend', function () {
                    content.classList.remove('expanding');
                    content.classList.add('expanded');
                    if (isModal) {
                        content.setAttribute('aria-modal', 'true');
                    }
                    content.setAttribute('role', 'dialog'); // it's not really clear if offcanvas elements really need the dialog role
                    content.focus(); //We could set focus to the first focusable element, but this can potentially cause destructive behavior if that element can cause a potentially irreversible action. Even if setting focus on the offcanvas element itself is not always the desired behavior if it can be avoided as specified by the W3C Modal Pattern, it is better to be safe when we don't know what type of content the offcanvas element will contain beforehand. The author can always override initial focus for each individual offcanvas element themselves afterwards.
                }, {
                    once: true
                });

                //Update the state of all the control buttons that need to be changed (todo: should probably trigger only once the transition has actually ended)
                this.UpdateButtonStates(this.controls);

            }
        },

        /**
         * fireEvent
         * @return void
         */
        fireEvent: function (control, type) {
            const _this = this;
            const targetElements = control.getAttribute('aria-controls').split(' ');

            if (control.hasAttribute('aria-controls')) {

                //Check if the control button has an aria-controls attribute specified
                if (control.getAttribute('aria-controls') !== '') {
                    //Check if the control button's aria-controls attribute isn't empty
                    for (let i = 0; i < targetElements.length; i++) {

                        //Get all the offcanvas elements whose id matches one of the values declared in the control button's aria-controls attribute
                        let content = document.getElementById(targetElements[i]);

                        if (content) {

                            //Open or close the offcanvas element depending on which type of control button was clicked (todo: shouldn't we add a toggle button for this as well?)
                            switch (type) {
                                case 'open':

                                    _this.openOffcanvas(content, control);

                                    break;

                                case 'close':

                                    if (content.getAttribute('data-offcanvas-content') === 'open') {
                                        _this.closeOffcanvas(content);
                                    }

                                    break;

                                case 'toggle':

                                    if (content.getAttribute('data-offcanvas-content') === 'closed') {
                                        _this.openOffcanvas(content, control);
                                    } else {
                                        _this.closeOffcanvas(content);
                                    }

                                    break;

                            }
                        } else {
                            // Handle the case where the element is not found
                            console.warn(`Offcanvas element with id '${targetElements[i]}' not found in the document.`);
                        }
                    }
                } else {
                    throw emptyAria;
                }
            } else {
                throw missingAria;
            }
        }
    };

    document.querySelectorAll('.js-offcanvas-content').forEach(function (element) {
        new Offcanvas(element);
    });

    const menuNav = document.getElementById('navigation-menu-mobile');
    new Offcanvas(menuNav, {
        focusIn: '.customfocusin2',
    });

    return 'offcanvas';
}
