// Import source styles
import '../scss/app.scss';

// Load External Libraries
import './bootstrap';
import AOS from 'aos'
import { Fancybox } from "@fancyapps/ui";
import { jarallax, jarallaxVideo } from 'jarallax';

// Local imports
import { validate } from './modules/_validate.mjs';
import { Disclosure } from "./modules/_disclosure.mjs";
import { navigation } from "./modules/_navigation.mjs";
import { offcanvas } from "./modules/_offcanvas.mjs";
import { headerscroll } from "./modules/_header.mjs";
import { dienstenSlider } from "./modules/_carousels.mjs";

document.addEventListener("DOMContentLoaded", function () {

    AOS.init({startEvent: 'load', disable: 'mobile'});
    jarallax(document.querySelectorAll('.jarallax'), {speed: 0.8});
    validate();
    navigation();
    offcanvas();
    headerscroll();
    dienstenSlider();

    const disclosure = new Disclosure();

    const nav = document.querySelector('.js-navigation');
    let dropdowns = nav.querySelectorAll('.navigation__dropdown');

    dropdowns.forEach(function(dropdown) {
        let submenu = dropdown.querySelector('.navigation__submenu');
        let navItem = dropdown.querySelector('.navigation__item')
        if (submenu.querySelectorAll('[aria-current]').length) {
            navItem.classList.add('aria-current-path');
        }
    });

});
