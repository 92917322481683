
//todo: for mobile, all items in the inactive slides should have a tabindex of -1
//todo: Disabled navigation items should not be focusable by either providing a tabindex of -1 or removing the href attribute.
//todo: add functionality for regular fade and fadeindown
//todo: aria-current-link support
//todo: adjust for RTL documents
//todo: close the menu if it was open on mobile when screen changes size (e.g. remove the backdrop)
//todo: menu patterns are a bit different. They require a menu to be closed when focus leaves the area.
//todo: the 'goback' button should not exist until the submenu has been opened, and only needs to exist on offcanvas navigation types

/*controls:
* Down arrow:
*
* Right arrow:
*
* Left arrow:
*
* Up arrow:
*
* Home:
*
* End:
*
* Escape:
*
*
*
*
*
* <!--The following Keyboard support has not been added yet:
-Implementing the optional arrow key support should prevent the default page scroll behavior when focus is contained within the data-disclosure region.
Down/Right arrow:
	-If focus is on a top-level link or top-level button and its dropdown is collapsed, and it is not the last top-level item, moves focus to the next top-level button or top-level link.
	-If focus is on a top-level button and its dropdown is expanded, moves focus to the first link in the dropdown.
	-If focus is on a link within an expanded dropdown, and it is not the last link, moves focus to the next link.
Up/Left Arrow:
	-If focus is on a top-level link or top-level button, and it is not the first top-level item, moves focus to the previous top-level button or top-level link.
	-If focus is on a link within an expanded dropdown, and it is not the first link, moves focus to the previous link.
Home:
	-If focus is on a top-level link or top-level button, and it is not the first top-level item, moves focus to the first top-level button or top-level link.
	-If focus is on a link within an expanded dropdown, and it is not the first link, moves focus to the first link.
End:
	-If focus is on a top-level link or top-level button, and it is not the last top-level item, moves focus to the last top-level button or top-level link.
	-If focus is on a link within an expanded dropdown, and it is not the last link, moves focus to the last link.
Escape (Required):
	-If a dropdown is open, and focus is inside the wrapping data-disclosure, closes it and sets focus on the button that controls that dropdown.
	-Moving focus out of the wrapping data-disclosure also closes the dropdown
-->
*
 */


export function navigation() {

    'use strict';

    const reducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)');

    function Navigation(options) {
        this.Init(options);
    }

    Navigation.prototype = {

        /**
         * Constructor
         * @Param object options
         * @return void
         */
        Init: function (options) {

            this.options = Object.assign({
                navs: '.js-navigation',
            }, options);

            this.navs = document.querySelectorAll(this.options.navs);

            this.Start();
        },

        Start: function () {

            const _this = this;
            const navs = this.navs;

            navs.forEach(function(nav) {
                const menus = nav.querySelectorAll('ul[data-level]');
                const items = nav.querySelectorAll('.navigation__item');
                const controls = nav.querySelectorAll('button.navigation__item[aria-controls]');
                let duration = nav.getAttribute('data-menu-duration');
                controls.forEach(function(control) {
                    control.setAttribute('aria-expanded', 'false');
                });
                if (duration) {
                    nav.querySelectorAll('ul[data-level]').forEach(function(menu) {
                        menu.style.transitionDuration =  parseInt(duration) + 'ms';
                    });
                }
                _this.SetEvents(nav, menus, items);
            });

        },

        /**
         * SetEvents
         * @return void
         */
        SetEvents: function (nav, menus, items) {

            const _this = this;

            menus.forEach(function(menu) {
                let level = menu.getAttribute('data-level');
                let controls = menu.querySelectorAll(':scope > li > button.navigation__item[aria-controls]');
                let submenus = menu.querySelectorAll('.navigation__submenu.expanded');
                const nav = menu.closest('.js-navigation');
                let duration = parseInt(nav.getAttribute('data-menu-duration'));

                menu.addEventListener('keydown', function (e) {
                    e.stopPropagation();
                    if (e.which === 27 && level > 1) {
                        if (menu.classList.contains('collapsing')) {
                            e.preventDefault();
                        } else {
                            menu.closest('.navigation__dropdown').classList.remove('active');
                            menu.classList.add('collapsing');
                            submenus.forEach(function(submenu) {
                                submenu.classList.add('collapsing');
                            });
                            menu.querySelectorAll('.navigation__item[aria-expanded=true]').forEach(function(item) {
                                item.setAttribute('aria-expanded', 'false');
                            });
                            setTimeout(function () {
                                menu.classList.remove('collapsing', 'expanded');
                                menu.style.display = 'none';
                                submenus.forEach(function(submenu) {
                                    submenu.style.display = 'none';
                                });
                            }, ((reducedMotion.matches || !reducedMotion) ? 0 : duration));
                            nav.querySelector('[aria-controls=' + menu.id + ']').setAttribute('aria-expanded', 'false');
                            nav.querySelector('[aria-controls=' + menu.id + ']').focus();
                        }
                    }
                });

                controls.forEach(function(control) {
                    control.addEventListener('click', function (e) {
                        let target = nav.querySelector('#' + control.getAttribute('aria-controls'));
                        if (target.classList.contains('expanding') || target.classList.contains('collapsing')) {
                            e.preventDefault();
                        } else {
                            fireEvent(control, target);
                        }
                    });
                });

                function fireEvent(control, target) {


/*
                    function setExpandedState(e) {

                        if ($control.getAttribute('aria-expanded') === 'true') {

                        } else {
                            $controls.forEach(function(ctrl) {
                                if (ctrl !== $control) {
                                    ctrl.setAttribute('aria-expanded', 'false');
                                }
                            });
                            $control.setAttribute('aria-expanded', 'true');

                            Array.from(document.querySelectorAll('[data-level]')).forEach(function(element) {
                                if (element.getAttribute('data-level') > parseInt($level)) {
                                    element.style.display = 'none';
                                    Array.from(element.querySelectorAll('button.nav-item[aria-controls]')).forEach(function(item) {
                                        item.setAttribute('aria-expanded', 'false');
                                    });
                                }
                            });

                            $target.style.display = 'block';
                        }
                    }
                    */


                    let submenus = target.querySelectorAll('[data-level]');

                    if (control.getAttribute('aria-expanded') === 'true') {
                        target.classList.add('collapsing');
                        target.closest('.navigation__dropdown').classList.remove('active');

                        target.querySelectorAll('.navigation__item[aria-expanded=true]').forEach(function (item) {
                            item.setAttribute('aria-expanded', 'false');
                        });
                        submenus.forEach(function (submenu) {
                            submenu.classList.add('collapsing');
                            submenu.querySelectorAll('.navigation__item[aria-expanded=true]').forEach(function (submenItem) {
                                submenu.setAttribute('aria-expanded', 'false');
                            });
                        });

                        target.closest('li').querySelector('[aria-controls=' + target.id + ']').setAttribute('aria-expanded', 'false');

                        setTimeout(function () {
                            target.classList.remove('collapsing', 'expanded');
                            target.style.display = 'none';
                            submenus.forEach(function (submenu) {
                                submenu.classList.remove('collapsing', 'expanded');
                                submenu.style.display = 'none';
                            });
                        }, ((reducedMotion.matches || !reducedMotion) ? 0 : duration));

                        control.setAttribute('aria-expanded', 'false');
                    } else {
                        controls.forEach(function(ctrl) {
                            if (ctrl !== control && !ctrl.closest('li.goback')) {
                                ctrl.setAttribute('aria-expanded', 'false');
                            }
                        });
                        control.setAttribute('aria-expanded', 'true');
                        target.querySelector('[aria-controls=' + target.id + ']').setAttribute('aria-expanded', 'true');

                        Array.from(document.querySelectorAll('[data-level]')).forEach(function(element) {
                            if (element.id !== target.id && element.getAttribute('data-level') > parseInt(level) && element.classList.contains('expanded')) {
                                element.classList.add('collapsing');
                                element.closest('.navigation__dropdown').classList.remove('active');
                                Array.from(element.querySelectorAll('.navigation__item[aria-controls]')).forEach(function(item) {
                                    item.setAttribute('aria-expanded', 'false');
                                });
                            }
                        });

                        setTimeout(function () {
                            document.querySelectorAll('[data-level]').forEach(function(element) {
                                if (element.getAttribute('data-level') > parseInt(level) && element.classList.contains('collapsing')) {
                                    element.classList.remove('collapsing', 'expanded');
                                    element.style.display = 'none';
                                    element.querySelectorAll('.navigation__item[aria-expanded=true]').forEach(function(item) {
                                        item.setAttribute('aria-expanded', 'false');
                                    });
                                }
                            });
                        }, ((reducedMotion.matches) ? 0 : duration));

                        target.style.display = 'block'; // Show the target element
                        target.closest('.navigation__dropdown').classList.add('active');
                        setTimeout(function () {
                            target.classList.add('expanding');
                        }, 30)
                        setTimeout(function () {
                            target.classList.add('expanded');
                            target.classList.remove('expanding');
                        }, ((reducedMotion.matches) ? 0 : duration));

                    }
                }

                if (window.innerWidth >= 1024 && nav.getAttribute('data-hover') === 'true') {
                    controls.forEach(function(ctrl) {
                        ctrl.closest('li').addEventListener('mouseenter', function(e) {
                            let control = this.querySelector('button.navigation__item[aria-controls]');
                            let target = document.getElementById(control.getAttribute('aria-controls'));
                            fireEvent(control, target);
                        });

                        ctrl.closest('li').addEventListener('mouseleave', function() {
                            let control = this.querySelector('button.navigation__item[aria-controls]');
                            let target = document.getElementById(control.getAttribute('aria-controls'));
                            target.classList.add('collapsing');
                            control.setAttribute('aria-expanded', 'false');
                            target.closest('.navigation__dropdown').classList.remove('active');
                            setTimeout(function() {
                                target.classList.remove('collapsing');
                                target.classList.remove('expanded');
                                target.style.display = 'none';
                            }, reducedMotion.matches ? 0 : duration);
                        });

                    });
                }
                //mouseleave should close the menu, not click. mouseleave function is used to ensure that loading a page with hover already on the disclosure item will close the item when the mouse leaves

            });

            items.forEach(function(item) {
                item.addEventListener('keydown', function(e) {
                    const keyCode = e.which;
                    let prevSibling = item.previousElementSibling;
                    let nextSibling = item.nextElementSibling;
                    switch (keyCode) {
                        case 32:
                            e.preventDefault();
                            item.click();
                            break;
                        case 35:
                            e.preventDefault();
                            item.closest('ul[data-level]').querySelector('> li:last-child .navigation__item').focus(); //needs to be tested
                            break;
                        case 36:
                            e.preventDefault();
                            item.closest('ul[data-level]').querySelector('> li:first-child .navigation__item').focus(); //needs to be tested
                            break;
                        case 37:
                            e.preventDefault();
                            if (prevSibling && prevSibling.classList.contains('navigation__item')) {
                                prevSibling.focus();
                            } else {
                                prevSibling = item.closest('li').previousElementSibling;
                                if (prevSibling) {
                                    let directChildren = Array.from(prevSibling.querySelectorAll('.navigation__item')).filter(child => child.parentNode === prevSibling);
                                    if (directChildren.length > 0) {
                                        let lastNavItem = directChildren[directChildren.length - 1];
                                        lastNavItem.focus();
                                    } else {
                                        return;
                                    }
                                } else {
                                    return;
                                }
                            }
                            break;
                        case 38:
                            e.preventDefault();
                            if (prevSibling && prevSibling.classList.contains('navigation__item')) {
                                prevSibling.focus();
                            } else {
                                prevSibling = item.closest('li').previousElementSibling;
                                console.log(prevSibling);
                                if (prevSibling) {
                                    let directChildren = Array.from(prevSibling.querySelectorAll('.navigation__item')).filter(child => child.parentNode === prevSibling);
                                    if (directChildren.length > 0) {
                                        let lastNavItem = directChildren[directChildren.length - 1];
                                        lastNavItem.focus();
                                    } else {
                                        return;
                                    }
                                } else {
                                    return;
                                }
                            }
                            break;
                        case 39:
                            e.preventDefault();
                            if (item.getAttribute('aria-expanded') === 'true' && item.closest('ul[data-level]').getAttribute('data-level') !== '1') {
                                const firstNavItem = document.getElementById(item.getAttribute('aria-controls')).querySelector('li:not(.goback) > .navigation__item');
                                if (firstNavItem) {
                                    firstNavItem.focus();
                                }
                            } else  {
                                if (nextSibling && nextSibling.classList.contains('navigation__item')) {
                                    nextSibling.focus();
                                } else {
                                    nextSibling = item.closest('li').nextElementSibling;
                                    if (nextSibling) {
                                        let directChildren = Array.from(nextSibling.querySelectorAll('.navigation__item')).filter(child => child.parentNode === nextSibling);
                                        if (directChildren.length > 0) {
                                            let firstNavItem = directChildren[0];
                                            firstNavItem.focus();
                                        } else {
                                            return;
                                        }
                                    } else {
                                        return;
                                    }
                                }
                            }
                            break;
                        case 40:
                            e.preventDefault();
                            if (item.getAttribute('aria-expanded') === 'true' && item.closest('ul[data-level]').getAttribute('data-level') === '1') {
                                const firstNavItem = document.getElementById(item.getAttribute('aria-controls')).querySelector('li:not(.goback) > .navigation__item');
                                if (firstNavItem) {
                                    firstNavItem.focus();
                                }
                            } else  {
                                let nextSibling = item.nextElementSibling;
                                if (nextSibling && nextSibling.classList.contains('navigation__item')) {
                                    nextSibling.focus();
                                } else {
                                    nextSibling = item.closest('li').nextElementSibling;
                                    if (nextSibling) {
                                        let directChildren = Array.from(nextSibling.querySelectorAll('.navigation__item')).filter(child => child.parentNode === nextSibling);
                                        if (directChildren.length > 0) {
                                            let firstNavItem = directChildren[0];
                                            firstNavItem.focus();
                                        } else {
                                            return;
                                        }
                                    } else {
                                        return;
                                    }
                                }
                            }
                            break;

                    }
                });
            });

            document.addEventListener('click', function (e) {
                const target = e.target;
                const isSubmenu = target.closest('ul[data-level] ul[data-level]');
                const isNavItem = target.classList.contains('navigation__item');

                if (!isSubmenu && !isNavItem) {

                    const activeControl = document.querySelector('.navigation__item[aria-expanded="true"]');
                    if (activeControl) {
                        const activeMenuId = activeControl.getAttribute('aria-controls');
                        const activeMenu = document.getElementById(activeMenuId);

                        activeMenu.classList.add('collapsing');
                        activeControl.setAttribute('aria-expanded', 'false');

                        activeMenu.closest('.navigation__dropdown').classList.remove('active');
                        setTimeout(function () {
                            activeMenu.classList.remove('collapsing', 'expanded');
                            activeMenu.style.display = 'none';
                        }, ((reducedMotion.matches) ? 0 : parseInt(nav.getAttribute('data-menu-duration'))));

                    }
                }
            });

        }
    }

    new Navigation();

    return 'navigation';
}
