// Javascript-Powered animations for the animating of elements to/from display: none; and visibility: hidden; and to/from height/width: auto;
// @author Jesse de Vries
// How to use: import the function you need, then call it while supplying a variable parameter that contains the DOM element you want to animate.
//todo: support for prefers-reduced-motion

// Declare variables for 'width' and 'height' so that the functions that need them can use them (todo: is there a more elegant solutions for this?)
let height;
let width;

// List of animations, so we can check against them for widgets that need them (such as inside a switch())
export const animationsList = {
    toggleHeight: 'heightToggle',
    toggleWidth: 'widthToggle',
    toggleFade: 'fadeToggle',
    toggleTransform: 'transformToggle'
}

// animate element from opacity: 0; to opacity: 1;
// ===============================================
export function fadeIn(element) {

    element.classList.add('active', 'transitioning'); // Set active state styles (setting display to block, visibility to visible, etc.) as well as transitioning styles, so it can actually transition from 0 to 1.
    element.style.opacity = '0'; // Make sure the element starts invisible

    // Use requestAnimationFrame for smoother animation from display: none, visibility: hidden, etc.
    requestAnimationFrame(function () {
        // Allow time for the rendering before transitioning
        setTimeout(function () {
            element.style.opacity = '1'; // change the element's style to fully opaque on next repaint when ready
        });
    });

    // When transition ends, remove the 'transitioning' class. This class is typically used to disable click Events while the element is still in the middle of transitioning.
    element.addEventListener('transitionend', function() {
        element.classList.remove('transitioning');
    });

}

// animate element from opacity: 1; to opacity: 0;
// ===============================================
export function fadeOut(element) {

    element.style.opacity = '0'; // Set the opacity back to 0
    element.classList.add('transitioning'); // add the transitioning styles back, so it can actually transition from 1 to 0.

    // When transition ends, remove the 'transitioning' class. This class is typically used to disable click Events while the element is still in the middle of transitioning. We also remove the 'active' class so the element can be disabled (e.g. by setting display to none, visibility to hidden, etc.)
    element.addEventListener('transitionend', function() {
        element.classList.remove('active', 'transitioning');
    }, {
        once: true // Removed the eventListener from the element when it is no longer visible, so it doesn't try to remove these classes again when the element's fade-in transition is triggered a 2nd time.
    });

}

// animate an element from a transformed state to transform: none;
// ===============================================================
export function transformIn(element) {

    element.classList.add('active', 'transitioning'); // Set active state styles (setting display to block, visibility to visible, etc.) as well as transitioning styles, so it can actually transition from transform to none.
    // Any transforms applied to the element should be declared in CSS

    // Use requestAnimationFrame for smoother animation from display: none, visibility: hidden, etc.
    requestAnimationFrame(function () {
        // Allow time for the rendering before transitioning
        setTimeout(function () {
            element.style.transform = 'none'; // Remove the element's transforms on next repaint when ready
        });
    });

    // When transition ends, remove the 'transitioning' class. This class is typically used to disable click Events while the element is still in the middle of transitioning.
    element.addEventListener('transitionend', function() {
        element.classList.remove('transitioning');
    });

}

//animate an element from transform: none; to a transformed state
// ==============================================================
export function transformOut(element) {

    element.style.transform = ''; // Reset the transform to whatever the author declared in the CSS
    element.classList.add('transitioning'); // add the transitioning styles back, so it can actually transition from none to the author-declared transform

    // When transition ends, remove the 'transitioning' class. This class is typically used to disable click Events while the element is still in the middle of transitioning. We also remove the 'active' class so the element can be disabled (e.g. by setting display to none, visibility to hidden, etc.)
    element.addEventListener('transitionend', function() {
        element.classList.remove('active', 'transitioning');
    }, {
        once: true // Remove the eventListener from the element when it is no longer visible, so it doesn't try to remove these classes again when the element's transform-to-none transition is triggered a 2nd time.
    });

}

//animate element from height: 0; to height: auto;
//IMPORTANT: animating an element's dimensions causes layout trashing and forces the browser to repaint on every frame of the transition, which is not performant. It can also cause issues when the viewport changes in the middle of a transition. Don't use it if you don't have to.
// ===============================================
export function expandHeight(element) {

    element.classList.add('active', 'transitioning'); // Set active state styles (setting display to block, visibility to visible, etc.) as well as transitioning styles, so it can actually transition from height: 0; to height: auto;

    element.style.height = 'auto'; // Set the element's height to auto after it becomes visible, so we can obtain the element's computed height
    height = element.scrollHeight + 'px'; // set the variable 'height' to be equal to the element's computed height (scrollbar included)

    element.style.height = '0px'; // Set the element's height back to 0 after we obtain the rendered height so the element can transition from it. This happens before the browser is able to repaint the element, so height: auto; is never actually displayed on screen.

    // Use requestAnimationFrame for smoother animation on repaint from display: none, visibility: hidden, etc.
    requestAnimationFrame(function () {
        // Allow time for the rendering before transitioning
        setTimeout(function () {
            element.style.height = height; // Set the element's height to be equal to the 'height' variable declared above
        });
    });

    // When transition ends, remove the 'transitioning' class. This class is typically used to disable click Events while the element is still in the middle of transitioning.
    element.addEventListener('transitionend', function() {
        element.classList.remove('transitioning');
        element.style.height = ''; // When the transition has finished, the element's height (contained in the 'height' variable) should be equal to the value of 'auto', so we can remove it. This ensures the height of the element can be recalculated when the viewport changes.
    });

}

//animate element from height: auto; to height: 0;
//IMPORTANT: animating an element's dimensions causes layout trashing and forces the browser to repaint on every frame of the transition, which is not performant. It can also cause issues when the viewport changes in the middle of a transition. Don't use it if you don't have to.
// ===============================================
export function collapseHeight(element) {

    height = element.scrollHeight + 'px'; // set the value of the variable 'height' to be equal to the element's computed height (scrollbar included), so we can use that to transition from
    element.style.height = height; // set the element's height to be equal to the 'height' variable. The height variable contains a fixed value that should be equal to 'auto'. We do this, because it's not possible to transition from height: auto;

    // Use requestAnimationFrame for smoother animation on repaint from display: none, visibility: hidden, etc.
    requestAnimationFrame(function () {
        // Allow time for the rendering before transitioning
        setTimeout(function () {
            element.style.height = '0px'; // Set the element's height to 0
            element.classList.add('transitioning'); // add the transitioning styles back, so it can actually transition from the computed height to 0. (todo: should this be at the very top?)
        });
    });

    // When transition ends, remove the 'transitioning' class. This class is typically used to disable click Events while the element is still in the middle of transitioning. We also remove the 'active' class so the element can be disabled (e.g. by setting display to none, visibility to hidden, etc.)
    element.addEventListener('transitionend', function() {
        element.classList.remove('active', 'transitioning');
    }, {
        once: true // Remove the eventListener from the element when it is no longer visible, so it doesn't try to remove these classes again when the element's height is expanded a 2nd time.
    });

}

//animate element from width: 0; to width: auto;
//IMPORTANT: animating an element's dimensions causes layout trashing and forces the browser to repaint on every frame of the transition, which is not performant. It can also cause issues when the viewport changes in the middle of a transition. Don't use it if you don't have to.
// =============================================
export function expandWidth(element) {

    element.classList.add('active', 'transitioning'); // Set active state styles (setting display to block, visibility to visible, etc.) as well as transitioning styles, so it can actually transition from height: 0; to height: auto;

    element.style.width = 'auto'; // Set the element's width to auto after it becomes visible, so we can obtain the element's computed width
    width = element.scrollWidth + 'px'; // set the variable 'width' to be equal to the element's computed width (scrollbar included)

    element.style.width = '0px'; // Set the element's width back to 0 after we obtain the rendered width so the element can transition from it. This happens before the browser is able to repaint the element, so width: auto; is never actually displayed on screen.

    // Use requestAnimationFrame for smoother animation on repaint from display: none, visibility: hidden, etc.
    requestAnimationFrame(function () {
        // Allow time for the rendering before transitioning
        setTimeout(function () {
            element.style.width = width; // Set the element's width to be equal to the 'width' variable declared above
        });
    });

    // When transition ends, remove the 'transitioning' class. This class is typically used to disable click Events while the element is still in the middle of transitioning.
    element.addEventListener('transitionend', function() {
        element.classList.remove('transitioning');
        element.style.width = ''; // When the transition has finished, the element's width (contained in the 'width' variable) should be equal to the value of 'auto', so we can remove it. This ensures the width of the element can be recalculated when the viewport changes.
    });

}

//animate element from width: auto; to width: 0;
//IMPORTANT: animating an element's dimensions causes layout trashing and forces the browser to repaint on every frame of the transition, which is not performant. It can also cause issues when the viewport changes in the middle of a transition. Don't use it if you don't have to.
// =============================================
export function collapseWidth(element) {

    width = element.scrollWidth + 'px'; // set the value of the variable 'width' to be equal to the element's computed width (scrollbar included), so we can use that to transition from
    element.style.width = width; // set the element's width to be equal to the 'width' variable. The width variable contains a fixed value that should be equal to 'auto'. We do this, because it's not possible to transition from width: auto;

    // Use requestAnimationFrame for smoother animation on repaint from display: none, visibility: hidden, etc.
    requestAnimationFrame(function () {
        // Allow time for the rendering before transitioning
        setTimeout(function () {
            element.style.width = '0px'; // Set the element's width to 0
            element.classList.add('transitioning'); // add the transitioning styles back, so it can actually transition from the computed width to 0. (todo: should this be at the very top?)
        });
    });

    // When transition ends, remove the 'transitioning' class. This class is typically used to disable click Events while the element is still in the middle of transitioning. We also remove the 'active' class so the element can be disabled (e.g. by setting display to none, visibility to hidden, etc.)
    element.addEventListener('transitionend', function() {
        element.classList.remove('active', 'transitioning');
    }, {
        once: true // Remove the eventListener from the element when it is no longer visible, so it doesn't try to remove these classes again when the element's width is expanded a 2nd time.
    });

}

