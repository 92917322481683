// todo: open and close buttons don't respond on page load yet, only after the disclosure script has fired at least once. This seems to have to do with updateButtonstates.

export function setActiveStartItems(contents, _this) {
    // If a URL hash exists, put decode it and put its value in a variable 'hash' and remove any '!' characters to make sure it doesn't break
    if (window.location.hash) {
        const hash = decodeURI(window.location.hash).replace(/(!)/g, "\\$1");
        const matchingContent = document.querySelector(hash + _this.options.contents); // Search the document for the first element that matches the value of 'hash', and put that in the variable 'contentHash'.
        if (matchingContent) { // Check if the contentHash actually contains an element, and if that element is a toggleable element // todo: we should probably not be looking for 'data-disclosure-content' directly, but for the value declared in this.options.contents so the two values always correspond to each other
            matchingContent.setAttribute('data-disclosure-content', 'open'); // If yes, set the element's state to 'open'
        }
    }

    contents.forEach(function (content) { // Iterate through all the toggleable elements on the page

        let animation = null;

        if (content.getAttribute('data-disclosure-content') === 'open' || content.querySelector('[data-disclosure-content=open]')) { // check if the toggleable element's state is set to 'open', or if it contains a toggleable child element that is set to 'open'

            content.setAttribute('data-disclosure-content', 'open'); // for toggleable elements that contain child elements that have their states set to 'open', set their states to 'open' to allow the children to be shown as well.
            // For toggleable elements that are set to the 'open' state, or contain child elements that have the 'open' state, jump to 'SetAnimation' so we can animate them in on page load. We use a small delay to make sure the browser is ready to render the animation
            animation = content.getAttribute('data-animate');
            if (!animation) {
                let parentElement = content.closest('[data-animate]');
                if (parentElement) {
                    animation = parentElement.getAttribute('data-animate');
                }
            }

            // don't execute animation if target element is set to be immediately visible
            if (content.classList.contains('active')) {
                return;
            }

            setTimeout(function() {
                _this.SetAnimation(content, animation);
            });
        } else {
            content.setAttribute('data-disclosure-content', 'closed'); // For all toggleable elements that do not have their state explicitly declared as 'open' in the HTML or match the variable of contentHash above, assume their state to be 'closed' on page load
        }
    });
}