// Javascript-Powered disclosure script for the displaying and hiding of elements on the page, and update their corresponding button elements
// @author Jesse de Vries

/*readme
* The Disclosure widget is a widget designed to expand or collapse content, or completely remove content from the DOM altogether.
* The widget consists of a control button and the section of content that is controlled by the control button.
* The state of the content's visibility is communicated to assistive technologies by using the aria-expanded attribute on the control button. This attribute is applied and updated accordingly, meaning authors are not required to apply it.
* The author is required to add an aria-controls attribute on the control button providing a space-separated list of elements referenced by their id for accessibility reasons and to indicate which elements should be targeted by the control button.
* CSS attribute selectors such as [aria-expanded] can be used to indicate the active or inactive state of the control buttons while styling the buttons, including their indicators.
* There are two ways to set a disclosure item's content to be visible on page load. The first is to only apply [data-disclosure-content=open] on the content. This will cause the content to animate in on page load.
* The second method is to provide both [data-disclosure-content=open] and the active class on the element. This will cause the content to be visible instantly, without an animation.
* Additionally, if the ID of the content is applied to the page's URL hash, it acts as if [data-disclosure-content=open] is set on the element and the page will scroll to the corresponding element. You don't have to apply [data-disclosure-content=open] manually in these circumstances, allowing you to control the visual state of content based on a redirect from another page.
* An additional effect of setting an id hash is that the first control button in the DOM that controls the element that matches the id hash will receive focus (to be implemented)
* */

// How to use:

// Button Controls: apply the attribute 'data-disclosure-control' on the button, and set it to the desired type (can be open, close, destroy or create, defaulting to a basic toggle button)
// Apply an 'aria-controls' attribute on the button, with a space-separated string corresponding to the id's of all elements the control button corresponds to.

// Toggleable Elements: apply the attribute 'data-disclosure-content' on the element, and set it to the state you want it to be when the page loads (either 'open' or 'close'). By default, it is assumed to be closed.
// If you additionally wish to have a toggle animation on the element as well, you must also provide the attribute 'data-disclosure-animate' with the value 'fadeToggle', 'heightToggle' 'widthToggle' or 'transformToggle'. If you don't provide the attribute, or the attribute contains an invalid value, no animation is applied to the element.
// It is possible for toggleable elements to inherit the animate behavior of the parent, as long as it finds a parent with a 'data-disclosure-animate' attribute and the item itself has no 'data-disclosure-animate' attribute explicitly declared.
// If you want to prevent DOM traversal from inheriting something you don't want, either assign 'data-disclosure-animate' on the items individually, or re-assign it on a parent element with the value you want (leave empty for no animation).

//example of usage with custom selectors:
// new Disclosure({
//     contents: '.custom-content-selector', // Override contents selector
//     controls: '.custom-control-selector' // Override controls selector
// });

//todo: allow for hover support, toggling content only on hover (requires a container around the content and the trigger button)

//todo: we might want to attach the event listener for controls to the body. This way, it should work for dynamically-added content

//todo: When a nested accordion item is accessed from a control outside the accordion, but accordion item's parent is closed, open the accordion parent accordion item, but only if the toggle button is used to open the content.

//todo: right now, we require each control and element to have an explicit type and animation set. It would be nice if we could target a parent element and have them inherit the parent declrations by default

//todo: when animate is set, but does not have a value, the implicit animation should depend on the context it is used in

//todo: add functionality that automtically closes items when they detect their parent was closed

//todo: add functionality to close items when click is not on their toggle button or the element itself

//todo: implement modal behavior working with dynamically-added modals

//todo: we're no longer passing our button type and testing against the presence of 'open' or 'close' when we go to the animations. We're instead relying on the 'disabled' attribute to disable buttons that correspond to open/closed items if they have the open/closed attributes in our button scripts. Is this sufficient? We're also no longer using a toggle() function, but instead checking for the presence of the 'active' class. We may want to instead test if the item is visible, rather than testing against an 'active' class, as jQuery does.

// todo: figure out a way to not require the animations.mjs file to be present, since the script works fine without it. All we need to do is throw a warning and create an empty animationsList object.
import { animationsList, fadeIn, fadeOut, transformIn, transformOut, expandHeight, collapseHeight, expandWidth, collapseWidth }  from "./_animations.mjs"; // import the animations from our animations script so the disclosure script can use them when toggling elements on the page
import { updateButtonStates } from "./_buttons.mjs";
import { setActiveStartItems } from "./_setActiveStartItems.mjs";

// Create a global list of options that we may need to update between different widgets that rely on disclosure().

    'use strict'; // enforce stricter parsing and error handling

    // Specify Console errors
    const missingAria = new TypeError("The control is missing an aria-controls attribute");
    const emptyAria = new TypeError("The provided aria-controls attribute is empty");

    // Create a variable to check against prefers-reduced-motion settings
    const reducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)');

    /**
     * Disclosure
     * @author Jesse de Vries
    */
    function Disclosure(options) {
        this.Init(options);
    }

    /**
     * Constructor
     * @Param object options
     * @return void
    */
    Disclosure.prototype = {
        // Initialize Object variables
        Init: function (options) { // Initialization

            // Default selectors
            const defaultOptions = {
                contents: '[data-disclosure-content]', // Toggleable elements
                controls: '[data-disclosure-control]' // Controls (buttons) used to toggle elements
            }

            // Merge default options with provided options
            this.options = Object.assign({}, defaultOptions, options);

            // Initialize Prototype variables
            this.contents = document.querySelectorAll(this.options.contents);
            this.controls = document.querySelectorAll(this.options.controls);

            // Initialize Start function
            this.Start();
        },

        /**
         * Start
         * @return void
        */
        // Executes on page load
        Start: function () { // Starting functionality

            const _this = this; // Put the current context of 'this' in a variable, so we can always pass it to the next function

            // Create local variables from the Object context
            const controls = this.controls;
            const contents = this.contents;

            function onLoadHandler() {
                setActiveStartItems(contents, _this);
                _this.UpdateButtonStates(controls);
                _this.SetEvents(controls, contents);
            }

            if (document.readyState === 'complete') {
                // If the document is already loaded, execute immediately
                onLoadHandler();
            } else {
                // Otherwise, add a listener for the 'load' event
                window.addEventListener('load', onLoadHandler);
            }

        },

        /**
         * Update Button States
         * @return void
        */
        // Update the button states
        // todo: We might want to move this function to its own script file that deals with button-related events and import it instead. That way we can always re-use it for other scripts that rely on checking button states. In particular, tab lists use a nearly identical implementation pattern, the only difference being the use of aria-selected on the buttons instead of aria-expanded.
        UpdateButtonStates: function (controls) {
            setTimeout(function () { // We set a delay here to make sure all toggleable elements updated their states before the buttons themselves do

                const contentState = 'aria-expanded'
                updateButtonStates(controls, contentState);

            });
        },

        /**
         * SetEvents
         * @return void
        */
        // Event Handling
        SetEvents: function (controls, contents) {
            const _this = this; // Put the current context of 'this' in a variable, so we can always pass it to the next function
            // todo: should this be debounced?
            controls.forEach(function (control) { // Iterate through all [data-disclosure-control] elements
                const type = control.getAttribute('data-disclosure-control'); // Get the attribute value of the element, which corresponds to its type
                control.addEventListener('click', function () { // todo: consider using Event delegation here
                    _this.fireEvent(this, type); // Jump to 'fireEvent', passing with it the instance of the button that was clicked and its type as arguments
                });
            });


            /*function closeOnFocusout(e, content) {

                _this.fireEvent(e.target, 'close', content);

                content.addEventListener('click', function (e) {
                    e.stopPropagation();
                });

            }


            document.addEventListener('click', function (e) {

                contents.forEach(function (content) {
                    if (content.getAttribute('data-close') && content.getAttribute('data-disclosure-content') !== 'closed') {
                        closeOnFocusout(e, content);
                    }
                });
            });*/



            //we are using this code for eventDelegation for dynamically-inserted content. However, we may want to attach a single event Listener on the body to control our disclosure in the future.
            const liveRegions = document.querySelectorAll('[aria-live]');
            liveRegions.forEach(function (liveRegion) {
                liveRegion.addEventListener('click', function (e) {
                    const target = e.target;
                    const control = target.closest('[data-disclosure-control]');
                    if (control) {
                        const type = control.getAttribute('data-disclosure-control');
                        _this.fireEvent(control, type);
                    }
                });
            });














        },

        /**
         * FireEvent
         * @return void
        */
        // Event handling logic
        fireEvent: function (control, type) {

            /*if (content) {
                content.setAttribute('data-disclosure-content', 'closed');
            }*/

            if (control.hasAttribute('aria-controls')) {

                const targetElements = control.getAttribute('aria-controls').split(' '); // Get the space-separated values of 'aria-controls' for the clicked button, and split them into separate strings in a 'targetElements' array
                const nonMatchingIds = []; // Array to store non-matching IDs

                if (control.getAttribute('aria-controls') !== '') {

                    for (let i = 0; i < targetElements.length; i++) { // Iterate through the 'targetElements' array

                        let content = document.getElementById(targetElements[i]); // Create a variable, 'content' (same as in the 'Start' function) for each item in targetElements, and set it to be equal to the element in the document with a matching ID.

                        if (!content) {
                            // If content is not found, add the ID to nonMatchingIds array
                            nonMatchingIds.push(targetElements[i]);
                        }

                        // Check if the 'content' variable actually contains any DOM elements
                        if (content) {

                            // Create a variable where we can store the type of animation that should be used
                            let animation = content.getAttribute('data-animate');

                            if (!animation) {
                                let parentElement = content.closest('[data-animate]');
                                if (parentElement) {
                                    animation = parentElement.getAttribute('data-animate');
                                }
                            }

                            // Debounce button presses when the toggleable element is still transitioning. This makes sure button states don't update and animations don't execute in quick succession.
                            if (content.classList.contains('transitioning')) {
                                return; // todo: Potential Duplication: If the fireEvent() function is called from multiple places within the widget. We may want a more centralized debounce somewhere
                            }

                            // Get the control button's [data-disclosure-control] value from a fixed set of options
                            switch (type) {
                                // If it's an 'open-only' control, change the states of the corresponding element(s) to 'open'.
                                case 'open':
                                    content.setAttribute('data-disclosure-content', 'open');
                                    break;
                                // If it's a 'close-only' control, change the states of the corresponding element(s) to 'closed'.
                                case 'close':
                                    content.setAttribute('data-disclosure-content', 'closed');
                                    break;

                                // If it's a control that destroys the corresponding element(s) (removes them from the DOM)
                                case 'destroy':

                                    // todo: We may want to move this code into its own separate script file that deals with content mutation and import it instead, so we can re-use it for other content in case we need it
                                    function removeContent() {
                                        content.remove(); // remove the element from the DOM

                                        // Remove all the control buttons whose aria-controls value matches the element's id. //todo: This does not work for control buttons that control multiple elements.
                                        let relatedControls = document.querySelectorAll('[data-disclosure-control][aria-controls="' + content.id + '"]');
                                        relatedControls.forEach(function (relatedControl) {
                                            relatedControl.remove();
                                        });
                                    }

                                    // Remove the corresponding element(s) immediately without waiting for a transition for visitors that have their motion preferences set to 'reduce', elements that are already in their 'closed' state, and items that have no/an invalid animation declared on them
                                    if (reducedMotion.matches || !Object.values(animationsList).includes(animation) || content.getAttribute('data-disclosure-content') === 'closed') {
                                        removeContent();
                                    } else {
                                        // Otherwise, wait for the animation to finish before removing the content from the DOM
                                        content.addEventListener('transitionend', function() {
                                            removeContent();
                                        });
                                    }
                                    break;

                                case 'create':
                                    // Todo: create a function that allows us to create new elements in the DOM...?
                                    break;
                                // Any value that is not one of the other values within this switch statement (including null/empty) will assume that the control button is a 'toggle' button
                                default:
                                    content.setAttribute('data-disclosure-content', (content.getAttribute('data-disclosure-content') === 'open') ? 'closed' : 'open'); // Changing the corresponding element(s) state to the opposite of what they were when the control button was clicked
                                    break;
                            }

                            // If the corresponding element is not currently in a 'transitioning' state, we jump to the 'SetAnimation' function, passing with it the current element
                            if (!content.classList.contains('transitioning')) {
                                this.SetAnimation(content, animation);
                            }
                        }
                        if (nonMatchingIds.length > 0) {
                            console.warn('No matching elements found in the document for the following IDs provided in "aria-controls":', nonMatchingIds);
                        }
                    }
                } else {
                    throw emptyAria; // Throw an error if an element with [data-disclosure-control] has an empty 'aria-controls' attribute, and stop executing the rest of the script
                }
            } else {
                throw missingAria; // Throw an error if an element with [data-disclosure-control] is missing an 'aria-controls' attribute, and stop executing the rest of the script
            }

            this.UpdateButtonStates(this.controls); // Update the control button states again once all the toggleable elements are done updating their states
        },

        /**
         * SetAnimation
         * @return void
        */
        // Animation handling
        SetAnimation: function (content, animation) {

            //replacement for jQuery's content.stop().hide() and content.stop().show(). We may want to test if the element is visible at all rather than test for the 'active' class and setting it, instead simply toggling display. (adding/removing display: none inline)
            function toggleContent() {
                if (!content.classList.contains('active')) {
                    content.classList.add('active');
                } else {
                    content.classList.remove('active');
                }
            }

            //othercontent is no longer controlled by the disclosure script. It is instead decided by the tablist, modal, navigation or accordion scripts, depending on the context. This particularly happens with the way the animations work. For example, accordion items should open/close at the same time, while active tablist items/modals should not have a fadeout animation to instantly make room for the next item.

            //todo: fade, height and width transitions currently do not work on nested content with parents that are not visible. (Their scrollHeight/scrollWidth defaults to 0px because the parent is also 0px, and 'transitionend' does not trigger because the element is not rendered when the parent is not. We should eventually fix this, but the situations where something like this occurs is pretty sparse

            // Check the visitor's motion preferences. If nothing is specified, execute the animation that is specified for the element. We need to set this check because in our CSS, we set the transition-duration to 0, which would mean that things break since 'transitionEnd' is never called. We could possibly rectify this by instead declaring a transition with a very low duration (like 1ms).
            if (!reducedMotion.matches) {
                //the duration of the animations is dictated by the animation-duration CSS property. It has a default that we can overwrite in our CSS whenever we want.

                // The animation used for toggling the element from a fixed set of options. Uses the class 'active' because it's what our _animations.mjs functions use // todo: the 'active' class indicates items that are expanded here. In _disclosure.mjs, we are using the value of [data-disclosure-content]. We should probably not rely on 2 separate values for this
                switch (animation) {

                    // Expand or collapse the element's height
                    case animationsList.toggleHeight:
                        if (!content.classList.contains('active')) {
                            expandHeight(content);
                        } else {
                            collapseHeight(content);
                        }
                        break;

                    // Expand or collapse the element's width
                    case animationsList.toggleWidth:
                        if (!content.classList.contains('active')) {
                            expandWidth(content);
                        } else {
                            collapseWidth(content);
                        }
                        break;

                    // Fade the element in or out
                    case animationsList.toggleFade:
                        if (!content.classList.contains('active')) {
                            fadeIn(content);
                        } else {
                            fadeOut(content);
                        }
                        break;

                    case animationsList.toggleTransform:
                        if (!content.classList.contains('active')) {
                            transformIn(content);
                        } else {
                            transformOut(content);
                        }
                        break;

                    // Any value that is not one of the other values within this switch statement (including null/empty) will assume that the element has no toggle animation, and apply the 'active' class to toggle the element's 'active' styles immediately.
                    // This is always the case for users that have their motion preferences set to reduced, because the check above the Switch Statement leaves the value of 'animation' as 'null'.
                    default:
                        toggleContent()
                        break;
                }

            } else {
                // I want the 'default' case in the Switch-statement above to execute for reduced-motion
                toggleContent()
            }
        }

    }

    export { Disclosure };
